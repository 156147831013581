<template>
  <base-table
    v-bind="attrs"
    ref="brandAssuranceTable"
    table-container-class=""
    class="border-separate border-spacing-0 border-b border-custom-gray-8"
    :class="areColumnsResizable ? '' : 'w-full'"
  >
    <template #tableHead>
      <thead class="border-0">
        <tr class="bg-custom-gray-10">
          <th
            v-if="showCheckBoxColumn"
            scope="col"
            class="p-2 text-center first:rounded-tl-sm last:rounded-tr-sm whitespace-nowrap relative"
          >
            <base-checkbox
              :model-value="areAllRowsSelected"
              @update:modelValue="handleSelectAllRows($event)"
            />
          </th>
          <template v-if="isTableDataLoaded">
            <base-resizable-column
              v-for="(col, cIndex) in tableData.columns"
              :key="'th-column' + cIndex"
              :model-value="areColumnsResizable && col.style ? col.style.width : 0"
              :table-rows="tableData.data.length"
              :is-column-resizable="areColumnsResizable"
              :table-selector="attrs.id ? `#${attrs.id}` : ''"
              :draggable="!isColumnResizing && areColumnsInterchangable"
              scope="col"
              class="p-2 text-left text-xs font-medium tracking-wider first:rounded-tl-sm last:rounded-tr-sm text-black whitespace-nowrap"
              :class="areColumnsSortable ? 'cursor-pointer' : ''"
              @click="handleSort(col.key, cIndex)"
              @dragstart.stop="handleDragStart($event, col, cIndex)"
              @dragover.prevent
              @drop.prevent="handleDrop(col, cIndex)"
              @resizingStart="updateColumnResizingStatus(true)"
              @resizingEnd="updateColumnResizingStatus(false)"
              @update:modelValue="handleColumnWidth($event, col)"
            >
              <template #default>
                <div class="flex justify-between items-center">
                  <span class="mr-1">
                    {{ col.label }}
                  </span>

                  <div
                    v-if="areColumnsSortable"
                    class="flex flex-col"
                  >
                    <base-svg
                      v-if="col.sorted && col.sorted === 'ASC'"
                      class="h-auto w-4 inline-block ml-2 py-1"
                      :class="col.sorted && col.sorted === 'ASC' ? 'text-primary-red' : 'text-gray-400'"
                      src="icons/arrowUpOutline.svg"
                      :svg-attributes="{
                        class: 'h-full w-full'
                      }"
                      tag="span"
                    />

                    <base-svg
                      v-else
                      class="h-auto w-4 inline-block  ml-2 py-1"
                      :class="col.sorted && col.sorted === 'DESC' ? 'text-primary-red' : 'text-gray-400'"
                      src="icons/arrowDownOutline.svg"
                      :svg-attributes="{
                        class: 'h-full w-full'
                      }"
                      tag="span"
                    />

                    <base-svg
                      v-if="sortedByIndex == cIndex"
                      class="h-auto w-3 ml-2 py-1 text-primary-red inline-block"
                      src="icons/circleSpinner.svg"
                      tag="span"
                    />
                  </div>
                  <div
                    v-else
                    class="flex flex-col w-4 h-auto ml-2 py-1"
                  />
                </div>
              </template>
            </base-resizable-column>
          </template>
        </tr>
      </thead>
    </template>
    <template #tableBody>
      <slot name="customTableBody">
        <tr
          v-for="(tableObj, trIndex) in tableData.data"
          :key="'table-row' + trIndex"
          class="text-sm"
          :class="tableObj.rowColor"
        >
          <td
            v-if="showCheckBoxColumn"
            class="p-2 whitespace-nowrap text-center border border-r-0 border-b-0 last:border-r border-custom-gray-8 text-xs"
          >
            <slot name="checkBox">
              <base-checkbox
                v-model="tableObj.selected"
              />
            </slot>
          </td>
          <td
            v-for="(col, cIndex) in tableData.columns"
            :key="'table-data' + cIndex"
            class="p-2 border border-r-0 border-b-0 last:border-r border-custom-gray-8 text-xs"
          >
            <span
              :class="tableObj[col.key]?.length > 10 ? 'truncate-inline' : ''"
              class="block"
              :title="tableObj[col.key]"
            >
              <slot
                :name="generateTableSlotName(trIndex, (showCheckBoxColumn ? cIndex + 1 : cIndex))"
                :rowIndex="trIndex"
                :colIndex="cIndex"
              >
                {{ tableObj[col.key] }}
              </slot>
            </span>
          </td>
        </tr>
      </slot>
    </template>
  </base-table>
  <div
    v-if="error"
    class="text-red-600"
  >
    {{ error }}
  </div>
  <teleport to="body">
    <div
      ref="ghostElement"
      class="absolute top-0 -left-80 text-black bg-custom-gray-9 inline-block p-2 text-left text-xs font-medium tracking-wider"
    >
      {{ draggedColumn ? draggedColumn.label : '' }}
    </div>
  </teleport>
</template>

<script>
import { computed, defineAsyncComponent, onMounted, reactive, ref, watch } from 'vue';
import useBaTable from '@/hooks/baTable.js';
import BaseCheckbox from '@/components/generic-components/BaseCheckbox.vue';

export default {
    name: 'BrandAssuranceTable',

    components: {
        BaseCheckbox,
        BaseResizableColumn: defineAsyncComponent(() => import('@/components/generic-components/BaseResizableColumn.vue')),
        BaseTable: defineAsyncComponent(() => import('@/components/generic-components/BaseTable.vue')),
        BaseSvg: defineAsyncComponent(() => import('@/components/generic-components/BaseSvg.vue'))
    },

    inheritAttrs: false,

    props: {
        modelValue: {
            type: Object,
            default: () => {}
        },
        areColumnsSortable: {
            type: Boolean,
            default: true,
            description: 'controls whether columns are sortable or not(sortable by default)'
        },
        showCheckBoxColumn: {
            type: Boolean,
            default: true,
            description: 'shows/hides checkbox column(shown by default)'
        },
        areColumnsResizable: {
            type: Boolean,
            default: false,
            description: 'controls whether table columns are resizable or not'
        },
        areColumnsInterchangable: {
            type: Boolean,
            default: false,
            description: 'controls whether table columns are interchangable or not(via drag and drop)'
        },
        useCustomSortLogic: {
            type: Boolean,
            default: false,
            description: 'if set to true, table avoids using default sorting logic and emits a custom event(named columnSorted with column\'s key being sorted and its current sort status as payload) instead'
        },
        error: {
            type: String,
            default: '',
            description: 'error message for table'
        }
    },

    emits: [
        'update:modelValue',
        'columnSorted', 'columnDragged', 'columnResized'
    ],

    setup (props, { attrs, emit }) {
        // 2 way data binding logic
        const tableData = reactive(props.modelValue);
        const isTableDataLoaded = ref(false);
        const transformTable = () => {
            transformTableColumns();
            transformTableData();
        };
        const transformTableColumns = () => {
            tableData.columns = tableData.columns.map(col => {
                return {
                    ...col,
                    style: { ...(col.style || { width: 0 }) },
                    sorted: null
                };
            });
        };
        const transformTableData = () => {
            tableData.data = tableData.data.map(tableObj => {
                return {
                    ...tableObj,
                    selected: false
                };
            });
        };
        onMounted(() => {
            transformTable();
            isTableDataLoaded.value = true;
        });
        const updateTableData = (updatedTableData) => {
            Object.entries(updatedTableData).forEach(([key, value]) => {
                tableData[key] = value;
            });
        };
        watch(
            () => props.modelValue,
            () => {
                updateTableData(props.modelValue);
            },
            { deep: true }
        );
        watch(
            () => tableData,
            () => {
                emit('update:modelValue', tableData);
                if (tableData.data && tableData.data.length && tableData.data.every(tableObj => tableObj.selected)) {
                    areAllRowsSelected.value = true;
                } else {
                    areAllRowsSelected.value = false;
                }
            },
            { deep: true }
        );

        // select all rows logic
        const areAllRowsSelected = ref(false);
        const handleSelectAllRows = (selectAll) => {
            areAllRowsSelected.value = selectAll;
            tableData.data.forEach(tableObj => {
                tableObj.selected = areAllRowsSelected.value;
            });
        };

        // sorting logic
        const handleSort = (colKey, colIndex) => {
            // sort only if columns are sortable
            if (props.areColumnsSortable) {
                // reset sorted flag for all columns, except for selected one
                tableData.columns.forEach((col, index) => {
                    if (colIndex !== index) {
                        col.sorted = null;
                    }
                });

                // get sort order
                let sortOrder;
                switch (tableData.columns[colIndex].sorted) {
                case 'ASC':
                    sortOrder = 'DESC';
                    break;
                case 'DESC':
                    sortOrder = null;
                    break;
                default:
                    sortOrder = 'ASC';
                }

                // emit event for sorting based on custom logic
                if (props.useCustomSortLogic) {
                    emit('columnSorted', {
                        columnKey: colKey,
                        columnIndex: colIndex,
                        sortOrder
                    });
                } else { // otherwise use default sorting logic
                    // if column is already sorted, simply reverse table data otherwise sort
                    if (tableData.columns[colIndex].sorted) {
                        tableData.data.reverse();
                    } else {
                        tableData.data.sort((a, b) => {
                            if (a[colKey] < b[colKey]) {
                                return -1;
                            } else if (a[colKey] > b[colKey]) {
                                return 1;
                            }
                            return 0;
                        });
                    }

                    // set sorted column flag
                    tableData.columns[colIndex].sorted = sortOrder;
                }
            }
        };

        // resize column logic
        const areTableColumnResized = computed(() => tableData && tableData.columns && tableData.columns.length > 0 && tableData.columns.some(col => col.style && col.style.height));
        const isColumnResizing = ref(false);
        const updateColumnResizingStatus = (resizingStatus) => {
            if (!resizingStatus) {
                emit('columnResized');
            }
            isColumnResizing.value = resizingStatus;
        };
        const handleColumnWidth = (updatedWidth, column) => {
            if (props.areColumnsResizable && column.style) {
                column.style.width = updatedWidth;
            }
        };

        // drag and drop column reordering logic
        const draggedColumn = ref(null);
        let draggedColumnIndex = null;
        const ghostElement = ref(null); // template ref for ghost element
        const handleDragStart = (event, column, colIndex) => {
            if (props.areColumnsInterchangable) {
                draggedColumn.value = column;
                draggedColumnIndex = colIndex;
                event.dataTransfer.setDragImage(ghostElement.value, 0, 0);
            }
        };
        const handleDrop = (targetColumn, targetColumnIndex) => {
            if (props.areColumnsInterchangable) {
            // swap columns if dragged and target columns are different
                if (draggedColumnIndex !== targetColumnIndex) {
                    tableData.columns.splice(targetColumnIndex, 1, draggedColumn.value);
                    tableData.columns.splice(draggedColumnIndex, 1, targetColumn);
                    emit('columnDragged');
                }
                draggedColumn.value = null;
                draggedColumnIndex = null;
            }
        };

        // cell slot logic
        const { generateTableSlotName } = useBaTable();

        return {
            attrs,
            tableData,
            isTableDataLoaded,
            // select all rows
            areAllRowsSelected,
            handleSelectAllRows,
            // sorting
            handleSort,
            // resize column
            areTableColumnResized,
            isColumnResizing,
            updateColumnResizingStatus,
            handleColumnWidth,
            // drag and drop column reordering
            draggedColumn,
            ghostElement,
            handleDragStart,
            handleDrop,
            // cell slot
            generateTableSlotName
        };
    }
};
</script>
<style scoped>
  .truncate-inline {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    word-break: break-all;
  }
</style>
